import React from "react";
import Modal from "../../../components/Modal/Modal";
import styles from "./LinkModal.pcss";
import { ReactComponent as ModalCloseIcon } from "../../../icons/ModalCloseIcon.svg";
import LinkForm from "../components/LinkForm/LinkForm";

const LinkModal = ({ isOpen, isEdit, editLink, onSuccess, close }) => (
  <Modal isOpen={isOpen}>
    <div className={styles.paper}>
      <div className={styles.modalControls}>
        <ModalCloseIcon onClick={close} />
      </div>
      <LinkForm isEdit={isEdit} editLink={editLink} onSuccess={onSuccess} />
    </div>
  </Modal>
);

export default LinkModal;
