import React from 'react';
import styles from "./StyledComponents.pcss";
import cx from 'classnames';

export const InputBase = ({ className, ...props}) => (
  <input
    className={cx(styles.inputBase, className)}
    autoComplete='off'
    {...props}
  />
);
