import React from 'react';
import PropTypes from 'prop-types';

export const Day = ({ date, onClick, cx, className = undefined }) => (
  <div
    className={cx('day', className)}
    onClick={() => onClick(date)}
  >
    {date.getDate()}
  </div>
);

Day.propTypes = {
  cx: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

Day.defaultProps = {
  className: undefined,
};
