import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from './modules/main/Main';
import StoreContextProvider from "./contexts";

const App = () => (
  <Router>
    <StoreContextProvider>
      <Main />
    </StoreContextProvider>
  </Router>
);

export default App;
