import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Icon from '../Icon';

import styles from './themes/b2b.pcss';
const cx = classNames.bind(styles);


export default function Button(props) {
  const {
    className,
    href,
    children,
    target,
    onClick,
    type,
    disabled,
    wide,
    transformer,
    adaptive,
    color,
  } = props;

  const Tag = href ? 'a' : 'button';

  const elClassName = cx(className, 'button', color);

  let iconScale;
  if (className && className.includes && className.includes('big')) {
    iconScale = 1.15;
  }

  const externalIcon = href && target === '_blank' ? (
    <Icon name="externalLink" className={cx('icon')} scale={iconScale} />
  ) : null;

  return (
    <div className={cx({ wrapper: true, wide, adaptive, transformer })}>
      <Tag
        className={elClassName}
        data-component="Button"
        href={href}
        target={target}
        onClick={onClick}
        type={href ? null : type}
        disabled={href ? null : disabled}
      >
        <span className={cx('text')}>
          {children}
        </span>
        {externalIcon}
      </Tag>
    </div>
  );
}

Button.defaultProps = {
  children: 'Отправить',
  type: 'submit',
};

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  href: PropTypes.string,
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  disabled: PropTypes.bool,
  wide: PropTypes.bool,
  adaptive: PropTypes.bool,
  transformer: PropTypes.bool,
  color: PropTypes.oneOf(['light', 'yellow']),
};
