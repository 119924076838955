import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cx from 'classnames';
import styles from './Pagination.pcss';

const getFirstNLastPages = (max, page, count) => {
  if (max >= count) {
    return [1, count];
  }
  const average = Math.floor(max / 2) + 1;
  if (page <= average) {
    return [1, max];
  }
  if (page > count - average) {
    return [count - max + 1, count];
  }
  return [page - average + 1, page + average - 1];
};


export default function Pagination({
  page = 1,
  pageCount = 1,
  onPageChange = noop,
  maxPages = 5,
  className
}) {

  const handlePageChange = useCallback(page => () => {
    onPageChange({ page });
  }, [onPageChange]);

  const [first, last] = useMemo(
    () => getFirstNLastPages(maxPages, page, pageCount),
    [maxPages, page, pageCount],
  );

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.container}>
        <button
          className={styles.pageNavBtn}
          onClick={handlePageChange(1)}
          disabled={page === 1 || pageCount === 0}
        >
          «
        </button>
        <button
          className={styles.pageNavBtn}
          onClick={handlePageChange(page - 1)}
          disabled={page === 1 || pageCount === 0}
        >
          ⟨
        </button>

        <div className={styles.pageBtnsContainer}>
          {/* create an array of required size */}
          {[...Array(last - first + 1)]
            .map((_, i) => i + first)
            .slice(0, last)
            .map(i => {
              if (page === i) {
                return (
                  <button key={i} className={cx(styles.pageBtn, styles.selected)}>
                    {i}
                  </button>
                );
              }
              return (
                <button key={i} onClick={handlePageChange(i)} className={styles.pageBtn}>
                  {i}
                </button>
              );
            })}
        </div>

        <button
          className={styles.pageNavBtn}
          onClick={handlePageChange(page + 1)}
          disabled={page === pageCount || pageCount === 0}
        >
          ⟩
        </button>
        <button
          className={styles.pageNavBtn}
          onClick={handlePageChange(pageCount)}
          disabled={page === pageCount || pageCount === 0}
        >
          »
        </button>
      </div>
    </div>
  );
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  maxPages: PropTypes.number,
  onPageChange: PropTypes.func,
};
