import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import addYears from 'date-fns/add_years';
import subYears from 'date-fns/sub_years';
import addMonths from 'date-fns/add_months';
import subMonths from 'date-fns/sub_months';
import isBefore from 'date-fns/is_before';
import isAfter from 'date-fns/is_after';
import isSameMonth from 'date-fns/is_same_month';
import isSameYear from 'date-fns/is_same_year';

import isSameDay from 'date-fns/is_same_day';

import Icon from '../Icon';

import { CalendarMonthView } from './components/MonthView';
import { CalendarHeader } from './components/Header';

import { getMonthAlias, weekDays } from './utils/utils';
import cx_default from './utils/styles.js';

export default class Calendar extends PureComponent {
  static propTypes = {
    value: PropTypes.instanceOf(Date).isRequired,
    viewDate: PropTypes.instanceOf(Date),
    hideTodayButton: PropTypes.bool,
    yearHeaderEnabled: PropTypes.bool,
    monthHeaderEnabled: PropTypes.bool,

    formatMonthHeader: PropTypes.func,
    formatYearHeader: PropTypes.func,

    onChange: PropTypes.func.isRequired,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
    cx: PropTypes.func,
    dateRangeMode: PropTypes.bool,
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    isTime: PropTypes.bool,
    endOfDay: PropTypes.bool,
  };

  static defaultProps = {
    value: new Date(),
    viewDate: null,
    hideTodayButton: false,
    yearHeaderEnabled: true,
    monthHeaderEnabled: true,
    min: undefined,
    max: undefined,
    dateRangeMode: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      viewDate: props.value,
      hours: '00',
      minutes: '00',
    };
  }
  componentDidMount(){
    const { value, endOfDay } = this.props;
    let hours = new Date(value).getHours() || (endOfDay ? '23' : '00');
    let minutes = new Date(value).getMinutes() || (endOfDay ? '59' : '00');
    if(parseInt(hours) < 10){
      hours = '0' + parseInt(hours)
    }
    if(parseInt(minutes) < 10){
      minutes = '0' + parseInt(minutes)
    }
    const viewDate = this.updateViewDate({});
    this.setState({hours, minutes, ...viewDate});
  }
  componentDidUpdate(prevProps) {
    const viewDate = this.updateViewDate(prevProps);
    this.setState({...viewDate});
  }
  updateViewDate = (prevProps) => {
    if(!this.props.viewDate) {
      return {};
    }
    const isPropsUpdated = !isSameDay(prevProps.viewDate, this.props.viewDate);
    if(isPropsUpdated) {
      if(this.props.viewDate instanceof Date && !isSameDay(prevProps.viewDate, this.props.viewDate)) {
        return {viewDate: this.props.viewDate};
      }
    }
    return {};
  }
  isAllowedPrevMonth = () => {
    const { min } = this.props;
    const prevMonth = subMonths(this.state.viewDate, 1);

    return !min || isSameMonth(prevMonth, min) || isAfter(prevMonth, min);
  }

  isAllowedNextMonth = () => {
    const { max } = this.props;
    const nextYear = addMonths(this.state.viewDate, 1);

    return !max || isSameMonth(nextYear, max) || isBefore(nextYear, max);
  }

  isAllowedPrevYear = () => {
    const { min } = this.props;
    const prevYear = subYears(this.state.viewDate, 1);

    return !min || isSameYear(prevYear, min) || isAfter(prevYear, min);
  }

  isAllowedNextYear = () => {
    const { max } = this.props;
    const nextYear = addYears(this.state.viewDate, 1);

    return !max || isSameYear(nextYear, max) || isBefore(nextYear, max);
  }

  handlePrevMonthSelect = () => this.setState({ viewDate: subMonths(this.state.viewDate, 1) });
  handleNextMonthSelect = () => this.setState({ viewDate: addMonths(this.state.viewDate, 1) });

  handlePrevYearSelect = () => this.setState({ viewDate: subYears(this.state.viewDate, 1) });
  handleNextYearSelect = () => this.setState({ viewDate: addYears(this.state.viewDate, 1) });

  handleDayClick = (date) => {
    this.setState({ viewDate: date }, this.startOnChange);
  }

  handleTimeChange = (e) => {
    const name = e.target.name;
    let value = parseInt(e.target.value) || 0;

    const isOutOfBounds = (name === 'hours' && value > 23) || value > 59;

    if (isOutOfBounds) {
      value = 0;
    }

    this.setState({[name]: value}, this.startOnChange);
  }

  startOnChange = () => {
    const date = new Date(this.state.viewDate);

    if (this.props.isTime){
      date.setHours(parseInt(this.state.hours));
      date.setMinutes(parseInt(this.state.minutes));
      this.setState({ viewDate: date });
    }

    this.props.onChange(date);
  }

  handlerFocus = (e) => {
    this.setState({[e.target.name]:''})

  }
  handlerBlur = (e) => {
    const name = e.target.name;
    if(!this.state[name]){
      this.setState({[e.target.name]:'00'})
    }
  }

  render() {
    const { className, isTime, yearHeaderEnabled, monthHeaderEnabled } = this.props;
    const cx = typeof this.props.cx === 'function' ? this.props.cx : cx_default;
    const formatMonthHeader = typeof this.props.formatMonthHeader === 'function'
      ? this.props.formatMonthHeader
      : (date) => getMonthAlias(date.getMonth())
    const formatYearHeader = this.props.formatYearHeader
      ? typeof this.props.formatYearHeader === 'function'
      : (date) => date.getFullYear()
      
    return (
      <div className={cx('wrapper', className)}>
        {yearHeaderEnabled ? <CalendarHeader
          isPrevAllowed={this.isAllowedPrevYear()}
          isNextAllowed={this.isAllowedNextYear()}
          onPrevClick={this.handlePrevYearSelect}
          onNextClick={this.handleNextYearSelect}
        >
          { formatYearHeader(this.state.viewDate) }
        </CalendarHeader> : null}
        {monthHeaderEnabled ? <CalendarHeader
          isPrevAllowed={this.isAllowedPrevMonth()}
          isNextAllowed={this.isAllowedNextMonth()}
          onPrevClick={this.handlePrevMonthSelect}
          onNextClick={this.handleNextMonthSelect}
        >
          { formatMonthHeader(this.state.viewDate) }
        </CalendarHeader> : null}
        <div className={cx('daysOfWeek')}>
          { weekDays.map(alias => <div className={cx('dayOfWeek')} key={`wday-alias:${alias}`}>{ alias }</div>) }
        </div>
        <CalendarMonthView
          cx={cx}
          month={this.state.viewDate}
          onDayClick={this.handleDayClick}
          min={this.props.min}
          max={this.props.max}
          selectedDay={this.props.value}
          dateRangeMode={this.props.dateRangeMode}
        />
        {
          isTime ?
            <div className={cx('time')}>

              <Icon
                name="clock"
                className={cx('clock')}
              />

              <input
                name="hours"
                className={cx('time-input')}
                value={this.state.hours}
                onBlur={this.handlerBlur}
                onFocus={this.handlerFocus}
                onChange={this.handleTimeChange}
              />

              <span className={cx('colon')}>:</span>

              <input
                name="minutes"
                className={cx('time-input')}
                value={this.state.minutes}
                onBlur={this.handlerBlur}
                onFocus={this.handlerFocus}
                onChange={this.handleTimeChange}
              />
            </div> :
            !this.props.hideTodayButton && <button
              className={cx('todayButton')}
              type="button"
              onClick={() => this.handleDayClick(new Date())}
            >
              сегодня
            </button>
        }

      </div>
    );
  }
}
