import {cloneDeep} from "lodash";

export const throttler = (throttledFunc, throttleTime) => {
  let props;
  let go = false;

  return (...allProps) => {
    props = cloneDeep(allProps);
    if (!go) {
      go = true;
      setTimeout(() => {
          throttledFunc(...props);
          go = false;
        },
        throttleTime,
      )
    }
  }
}
