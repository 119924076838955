import React from 'react';
import style from './LinksTable.pcss';
import { ReactComponent as PenIcon } from "../../../icons/PenIcon.svg";

const Edit = ({ onClick }) => {
  return (
    <div className={style.edit} onClick={onClick}>
      <PenIcon />
    </div>
  )
}

export default Edit;
