import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as arrowLeft} from './assets/arrowLeft.svg';
import { ReactComponent as checked } from './assets/checked.svg';
import { ReactComponent as cross } from './assets/cross.svg';
import { ReactComponent as documents } from './assets/documents.svg';
import { ReactComponent as dropdownArrow } from './assets/dropdownArrow.svg';
import { ReactComponent as externalLink } from './assets/externalLink.svg';
import { ReactComponent as clock } from './assets/clock.svg';

const icons = {
  arrowLeft,
  checked,
  cross,
  documents,
  dropdownArrow,
  externalLink,
  clock,
};

const sizes = {
  arrowLeft: { width: 30, height: 12 },
  checked: { width: 11, height: 9 },
  cross: { width: 12, height: 12 },
  documents: { width: 28, height: 37 },
  dropdownArrow: { width: 14, height: 8 },
  externalLink: { width: 13, height: 13 },
  present: { width: 25, height: 30 }, // loads over network
  clock: { width: 13, height: 13 },
};

export default function Icon(props) {
  const { name, className, scale, src } = props;
  const Svg = icons[name];
  const style = {
    width: sizes[name] ? sizes[name].width * scale : null,
    height: sizes[name] ? sizes[name].height * scale : null,
  };

  return Svg ?
    <Svg className={className} {...style} /> :
    <img
      className={className}
      src={src.replace('{{name}}', name)}
      alt={name}
      style={style}
    />;
}

Icon.defaultProps = {
  scale: 1,
  src: '//static.beeline.ru/upload/images/emoji/{{name}}.svg',
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.any,
  scale: PropTypes.number,
  src: PropTypes.string,
};
