import React, {useState} from 'react';
import styles from './Cutter.pcss';
import LinksList from "./LinksTable/LinksTable";
import Heading from 'uikit/components/Heading';
import { InputBase } from "../../StyledComponents/StyledComponents";
import Button from "../../uikit/components/Button";
import LinkModal from "./LinkModal/LinkModal";

const Cutter = () => {
  const [search, setSearch] = useState(null);
  const [openedModal, setOpenedModal] = useState();
  const onSearchChange = (e) => {
    const value = e.currentTarget.value;
    if (value && value.length >= 2) {
      setSearch(value);
    } else {
      setSearch(null);
    }
  }

  const openCreateLinkModal = () => setOpenedModal({ modalType: 'CreateLinkModal' });

  const openEditLinkModal = (item) => () => setOpenedModal({ modalType: 'EditLinkModal', editLink: item });

  const closeModal = () => setOpenedModal(null);

  const onSearchClear = () => setSearch(null);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img alt="" className={styles.image} src={"/static/images/icon-cut.png"}/>
        <Heading className={styles.title} level={2} isStyled={false}>Укорачиватель</Heading>
        <InputBase
          className={styles.search}
          value={search}
          placeholder='Поиск'
          clearable
          onChange={onSearchChange}
          onClear={onSearchClear}
        />
        <Button className={styles.button} onClick={openCreateLinkModal}>Укорачивание ссылки</Button>
      </div>
      <LinksList search={search} openedModal={openedModal} closeModal={closeModal} openEditLinkModal={openEditLinkModal} />
    </div>
  );
}

export default Cutter;
