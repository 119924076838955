import React, { useState, useEffect,} from 'react';
import addYears from 'date-fns/add_years';
import addMonths from 'date-fns/add_months';

import Calendar from 'uikit/components/Calendar'
import Button from 'uikit/components/Button';
import { getMonthAlias } from 'uikit/components/Calendar/utils/utils';
import { HOST } from '../../../../constants';
import cx from './styles.js';
import styles from './LinkForm.pcss';
import { InputBase } from "../../../../StyledComponents/StyledComponents";
import api from "../../../../api";

const formatMonthHeader = (date) => `${getMonthAlias(date.getMonth())}, ${date.getFullYear()}`.toLowerCase();

const daysToMS = (days) => days * 3600 * 24 * 1000;
const appendDaysToNow = (days) => new Date(Date.now() + daysToMS(days))

const getFullLinkValue = value => {
  if (value === '') return HOST;

  const split = value.split(HOST);
  if (split.length === 2 && split[0] === "") return value;
}

const getPureLinkValue = validValue => (validValue.split(HOST)[1]);

const LinkForm = ({ isEdit, editLink, onSuccess }) => {
  const [link, setLink] = useState(HOST);
  const [address, setAddress] = useState('');
  const [viewDateLeft] = useState(null);
  const [viewDateRight, setViewDateRight] = useState(appendDaysToNow(30));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sendingState, setSendingState] = useState();
  const [errorMessage, setErrorMessage] = useState(null);

  const createLinkRequest = async (data) => {
    setSendingState('requested');
    try {
      await api.post(`/api/link/`, data);
      onSuccess();
      setSendingState('succeed');
    } catch (e) {
      setErrorMessage('Ссылка уже существует');
      setSendingState('failed');
    }
  }

  const editLinkRequest = async (data) => {
    setSendingState('requested');
    try {
      await api.put(`/api/link/`, data);
      onSuccess();
      setSendingState('succeed');
    } catch (e) {
      setErrorMessage('Произошла ошибка');
      setSendingState('failed');
    }
  }


  useEffect(() => {
    if (isEdit && editLink && editLink.expires) {
      setSelectedDate(new Date(editLink.expires));
    }
  }, [isEdit, editLink, setSelectedDate]);

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage(null);
    }
  }, [link])

  const changeLink = e => setLink(getFullLinkValue(e.currentTarget.value));

  const edit = () => {
    editLinkRequest({
      id: editLink.id,
      // address,
      expires: selectedDate.toUTCString(),
    })
  }
  const create = () => {
    createLinkRequest({
      link: getPureLinkValue(link),
      address,
      expires: selectedDate.toUTCString(),
    });
  }
  const handleSave = () => isEdit ? edit() : create();

  const appendDate = (date) => () => {
    let newDate = null;
    switch (date) {
      case 'month':
        newDate = addMonths(Date.now(), 1);
        break;
      case 'year':
        newDate = addYears(Date.now(), 1);
        break;
      default:
        newDate = appendDaysToNow(date);
        break;
    }
    setViewDateRight(newDate);
    setSelectedDate(newDate);
  }

  const changeAddress = (e) => {
    setAddress(e.currentTarget.value)
  }

  return (
    <div className={cx('container')}>
      <div className={cx('buttons')}>
        <div onClick={appendDate(1)}>день</div>
        <div onClick={appendDate(7)}>неделя</div>
        <div onClick={appendDate('month')}>месяц</div>
        <div onClick={appendDate('year')}>год</div>
      </div>
      <div className={styles.calendar}>
        <Calendar
          value={selectedDate}
          viewDate={viewDateLeft}
          onChange={setSelectedDate}
          yearHeaderEnabled={false}
          formatMonthHeader={formatMonthHeader}
          min={new Date()}
          cx={cx}
          dateRangeMode
          hideTodayButton
        />
        <Calendar
          value={selectedDate}
          viewDate={viewDateRight}
          onChange={setSelectedDate}
          yearHeaderEnabled={false}
          formatMonthHeader={formatMonthHeader}
          min={new Date()}
          cx={cx}
          dateRangeMode
          hideTodayButton
        />
      </div>

      {!isEdit && (
        <>
          <InputBase className={styles.linkInput} value={link} onChange={changeLink} />
          <InputBase value={address} onChange={changeAddress} />
        </>
      )}

      <div className={styles.controls}>
        <Button onClick={handleSave} disabled={sendingState === 'requested'}>Сохранить</Button>
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
}

export default LinkForm;
