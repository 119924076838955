import axios from 'axios';

export const BASE_URL = `${window.location.protocol}//${window.location.host}`;

const api = axios.create({
  baseURL: BASE_URL
})

api.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('cutter_token')}`;
  return config;
})

api.interceptors.response.use(config => {
  return config;
}, async (error) => {
  if (error?.response?.status === 401) {
    localStorage.setItem('cutter_token', '');
    return window.location.reload();
  }
  throw error;
})

export default api;
