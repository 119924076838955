exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".j3lwfPepuwTSourDVp3qt {\r\n\tmargin: 160px auto 0 auto;\r\n  padding: 25px 25px;\r\n  width: 50%;\r\n}\r\n\r\n._2vSEGGC5vlD9bhlmyVzGLO {\r\n    text-align: left;\r\n}\r\n\r\n._1R_f-9bOm1KGU6dkPEVawq {\r\n    white-space: pre;\r\n}\r\n\r\n.HuEih-Hzuaqneu0kAP-_w {\r\n\tmargin-bottom: 16px;\r\n}\r\n\r\n._2N9LyM31XOiA-4F8nB_TP4 {\r\n\tmargin-bottom: 24px;\r\n}\r\n", "",{"version":3,"sources":["Authorization.pcss"],"names":[],"mappings":"AAAA;CACC,yBAAyB;EACxB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,mBAAmB;AACpB","file":"Authorization.pcss","sourcesContent":[".form {\r\n\tmargin: 160px auto 0 auto;\r\n  padding: 25px 25px;\r\n  width: 50%;\r\n}\r\n\r\n.title {\r\n    text-align: left;\r\n}\r\n\r\n.description {\r\n    white-space: pre;\r\n}\r\n\r\n.loginInput {\r\n\tmargin-bottom: 16px;\r\n}\r\n\r\n.passInput {\r\n\tmargin-bottom: 24px;\r\n}\r\n"]}]);

// Exports
exports.locals = {
	"form": "j3lwfPepuwTSourDVp3qt",
	"title": "_2vSEGGC5vlD9bhlmyVzGLO",
	"description": "_1R_f-9bOm1KGU6dkPEVawq",
	"loginInput": "HuEih-Hzuaqneu0kAP-_w",
	"passInput": "_2N9LyM31XOiA-4F8nB_TP4"
};