import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useContextSelector } from "use-context-selector";
import Authorization from '../authorization/Authorization';
import Cutter from '../cutter/Cutter';

import styles from './Main.pcss';
import { StoreContext } from "../../contexts";
const renderUnknownPath = () => <Redirect to="/" />;

function Main() {
  const isAuth = useContextSelector(StoreContext, state => state.isAuth);
  const changeStoreProp = useContextSelector(StoreContext, state => state.changeStoreProp);

  useEffect(() => {
    const token = localStorage.getItem('cutter_token');
    if (token) {
      changeStoreProp('isAuth', true);
    } else {
      changeStoreProp('isAuth', false);
    }
  }, [])

  if (isAuth === null) return null;

  if (!isAuth) {
    return (
      <div className={styles.wrapper}>
        <Authorization />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Switch>
        <Route path="/" component={Cutter} />
        <Route render={renderUnknownPath} />
      </Switch>
    </div>
  );
}

export default Main;
