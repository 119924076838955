import React, { useEffect, useState, useMemo, useRef } from 'react';
import Table from 'components/Table';
import Checkbox from 'uikit/components/Checkbox';
import Link from '../components/Link/Link';
import TimeField from '../components/TimeField/TimeField';
import Edit from './ButtonEdit';
import styles from './LinksTable.pcss';
import api from "../../../api";
import Pagination from 'components/Pagination';
import {throttler} from "../../../utils";
import axios from "axios";
import { EXAMPLE } from "../Example";
import LinkModal from "../LinkModal/LinkModal";

const Links = ({ search, openedModal, closeModal, openEditLinkModal }) => {
  const [page, setPage] = useState(1);
  const [checked, setChecked] = useState({});
  const [allChecked, setAllChecked] = useState(false);
  const [links, setLinks] = useState([]);
  const [loadingState, setLoadingState] = useState('requested');
  const searchPage = useRef(1);
  const [pageCount, setPageCount] = useState(0);

  const [isFirst, setIsFirst] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setIsFirst(false), 0);
    return () => clearTimeout(timeout);
  }, [])

  let cancelToken;
  const fetchLinksRequest = async (params) => {
    setLoadingState('requested');
    try {
      if (cancelToken) cancelToken.cancel("Operation canceled");
      cancelToken = axios.CancelToken.source();
      const res = await api.get(`/api/links/`, { params, cancelToken: cancelToken.token })
      cancelToken = null;
      setPageCount(res.data.pageCount|| 0);
      setLinks(res.data.links || []);
      setLoadingState('succeed');
    } catch (e) {
      console.log(e);
      if (e.message !== 'Operation canceled') {
        setLoadingState('failed');
      }
    }
  }

  const fetchLinks = (page, search, searchPage) => {
    let params = {
      page: page,
      pageSize: 50,
    };

    if (search && search.length >= 2) {
      params.search = search;
      params.page = searchPage;
    }

    for (let key in params) {
      if (!params[key]) delete params[key];
    }

    fetchLinksRequest(params);
  }

  const fetchCampaignsThrottle = useMemo(
    () => throttler(fetchLinks, 500),
    []
  );

  useEffect(() => {
    fetchCampaignsThrottle(page, search, searchPage.current);
  }, [search]);

  useEffect(() => {
    if (!isFirst) {
      fetchLinks(page, search, searchPage.current);
    }
  }, [page]);

  const changePage = ({ page }) => {
    if (!search || search.length < 2) return setPage(page);
    searchPage.current = page;
    fetchLinks(page, search, page);
  }

  const handleCheckAll = () => {
    if (allChecked) {
      setAllChecked(false);
      setChecked({});
      return;
    }
    const checkMap = {}
    links.forEach((link) => checkMap[link.link] = true)
    setChecked(checkMap);
    setAllChecked(true);
  }

  const handleCheck = (link) => () => {
    if (checked[link]) {
      setChecked({...checked, [link]: false});
      setAllChecked(false);
    } else {
      setChecked({...checked, [link]: true});
    }
  }

  const sortBy = true;

  const head = [
    {id: 'check', label: <Checkbox handleChange={handleCheckAll} checked={allChecked} />},
    {id: 'edit', label: ""},
    {id: 'link', label: 'Короткая ссылка', sortBy},
    {id: 'address', label: 'Адрес ссылки', sortBy},
    {id: 'createdTime', label: 'Дата создания', sortBy},
    // {id: 'creator', label: 'Создатель', sortBy},
    {id: 'expires', label: 'Срок действия', sortBy},
    {id: 'uses', label: 'Переходов', sortBy}
  ]

  const renderer = (links) => {
    return links.map((item) => {
      return {
        check: <Checkbox handleChange={handleCheck(item.link)} checked={checked[item.link]} />,
        edit: <Edit onClick={openEditLinkModal(item)} />,
        link: <Link nameLink={item.link} pathLink={item.link}/>,
        address: <Link nameLink={item.address} pathLink={item.address} external />,
        createdTime: <TimeField value={item.createdTime} />,
        // creator,
        expires: <TimeField value={item.expires} />,
        uses: item.uses,
      }
    })
  }

  useEffect(() => {
    searchPage.current = 1;
  }, [search])

  const onLinkModalSuccess = () => {
    closeModal();
    fetchLinks(page, search, searchPage.current);
  }

  return (
    <div className={`${styles.list}`}>
      <Table head={head} values={renderer(links)} loadingState={loadingState} />
      <Pagination page={search && search.length >= 2 ? searchPage.current : page} pageCount={pageCount} onPageChange={changePage} />
      <LinkModal
        isOpen={openedModal}
        isEdit={openedModal?.modalType === 'EditLinkModal'}
        editLink={openedModal?.editLink || null}
        onSuccess={onLinkModalSuccess}
        close={closeModal}
      />
    </div>
  );
}


export default Links;
