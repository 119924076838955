import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { ReactComponent as Img } from './image.svg';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export default class Preloader extends Component {
  static defaultProps = {
    size: '48',
  }

  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
  }

  render() {
    const { size, className } = this.props;

    return (
      <div className={cx('component', className)}>
        <Img
          width={size}
          height={size}
          {...this.props}
          className={cx('image')}
        />
      </div>
    );
  }
}
