import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BaseModal from "@material-ui/core/Modal";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    outline: "none",
    whiteSpace: 'pre-wrap'
  }
}));

const Modal = ({ isOpen, children }) => {
  const classes = useStyles();

  return (
    <BaseModal className={classes.modal} open={isOpen}>
      <div className={classes.paper}>
        {children}
      </div>
    </BaseModal>
  );
}

export default Modal;
