import React from 'react';
import PropTypes from 'prop-types';

import cx from './../utils/styles';

const emptyCallback = () => undefined;

export const CalendarHeader = ({
  children,
  className,
  onPrevClick,
  onNextClick,
  isPrevAllowed = true,
  isNextAllowed = true,
}) => (
  <div className={cx('header', className)}>
    <button
      type="button"
      onClick={isPrevAllowed ? onPrevClick : emptyCallback}
      className={cx('button', 'prev', { blocked: !isPrevAllowed })}
    />
    { children }
    <button
      type="button"
      onClick={isNextAllowed ? onNextClick : emptyCallback}
      className={cx('button', 'next', { blocked: !isNextAllowed })}
    />
  </div>
);

CalendarHeader.propTypes = {
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  isPrevAllowed: PropTypes.bool,
  isNextAllowed: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

CalendarHeader.defaultProps = {
  isPrevAllowed: true,
  isNextAllowed: true,
};
