import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.pcss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

export default function Heading(props) {
  const { children, level, className } = props;

  const Tag = `h${level}`;

  return (
    <Tag
      className={cx({
        heading: true,
        [`h${[level]}`]: level,
        [className]: className,
      })}
    >{children}</Tag>
  );
}

Heading.defaultProps = {
  level: 3,
};

Heading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  className: PropTypes.any,
  level: PropTypes.number,
};
