import isSameDay from 'date-fns/is_same_day';
import addDays from 'date-fns/add_days';
import endOfWeek from 'date-fns/end_of_week';
import startOfWeek from 'date-fns/start_of_week';
import endOfMonth from 'date-fns/end_of_month';
import startOfMonth from 'date-fns/start_of_month';
import isBefore from 'date-fns/is_before';

export const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];
export const weekDays = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

const weekConfig = {
  weekStartsOn: 1,
};

export const buildDatePeriodArray = (start, end) => {
  const result = [];
  let currentDate = new Date(start);

  do {
    result.push(currentDate);
    currentDate = addDays(currentDate, 1);
  } while (isSameDay(currentDate, end) || isBefore(currentDate, end));

  return result;
};

export const buildMonthDaysWithAdditionalMonths = date => buildDatePeriodArray(
  startOfWeek(startOfMonth(date), weekConfig),
  endOfWeek(endOfMonth(date), weekConfig),
);

export const getMonthAlias = monthNum => months[monthNum];
