import React from 'react';

import { HOST } from '../../../../constants';

const DEFAULT_MAX_LENGTH = HOST.length + 10;

const Link = ({ nameLink, pathLink, maxLength = DEFAULT_MAX_LENGTH, external = false }) => {
  const hostValue = external ? nameLink : HOST + nameLink;
  const shortenedValue = hostValue ? hostValue.length > maxLength ? `${hostValue.substring(0, maxLength)}...` : hostValue : hostValue;
  return <a rel="noopener noreferrer" title={hostValue} target="_blank" href={pathLink}>{shortenedValue}</a>
}

export default Link;
