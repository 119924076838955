import React from 'react';
import PropTypes from 'prop-types';
import isSameDay from 'date-fns/is_same_day';
import isAfter from 'date-fns/is_after';
import isBefore from 'date-fns/is_before';
import isSameMonth from 'date-fns/is_same_month';

import { Day } from './Day';

import { buildMonthDaysWithAdditionalMonths } from './../utils/utils';
const isDayBlockedMin = (date, min) => min && !isSameDay(date, min) && isBefore(date, min);
const isDayBlockedMax = (date, max) => max && !isSameDay(date, max) && isAfter(date, max);
const isDayBlocked = (date, min, max) => isDayBlockedMin(date, min) || isDayBlockedMax(date, max);

export const CalendarMonthView = ({ month, onDayClick, selectedDay, min, max, cx, dateRangeMode }) => (
  <div className={cx('days')}>
    {
      buildMonthDaysWithAdditionalMonths(month)
        .map(date => {
          const isToday = isSameDay(date, new Date());
          const isSelected = selectedDay && dateRangeMode
            ? (isAfter(date, min) && isBefore(date, selectedDay)) || isSameDay(date, selectedDay) 
            : selectedDay && isSameDay(date, selectedDay);
          
          const isBlocked = isDayBlocked(date, min, max);
          const isOtherMonth = isSelected ? false : !isSameMonth(date, month);
          return <Day
            date={date}
            className={{
              otherMonth: isOtherMonth,
              blockedDay: isBlocked,
              active: isSelected,
              today: isToday,
            }}
            cx={cx}
            onClick={isBlocked ? () => false : onDayClick}
            key={`calday:${date.toISOString()}`}
          />
        })
    }
  </div>
);

CalendarMonthView.propTypes = {
  selectedDay: PropTypes.instanceOf(Date),
  month: PropTypes.instanceOf(Date).isRequired,
  cx: PropTypes.func.isRequired,
  onDayClick: PropTypes.func,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
};

CalendarMonthView.defaultProps = {
  selectedDay: undefined,
  min: undefined,
  max: undefined,
  onDayClick: () => undefined,
};
