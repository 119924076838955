import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon from '../Icon';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

const Checkbox = (props) => {
  const { children, name, checked, disabled, handleChange, className } = props;
  const id = `checkbox__${name}`;

  return (
    <div className={cx('layout', { disabled }, className)}>
      <span className={cx('checkbox', { checked })}>
        <input
          className={cx('field')}
          data-component="Checkbox"
          type="checkbox"
          id={id}
          checked={checked}
          name={name}
          disabled={disabled}
          onChange={handleChange}
          onClick={handleChange}
        />
        <Icon
          name="checked"
          className={cx('icon', { checked })}
        />
      </span>
      {children ? (
        <label className={cx('label')} htmlFor={id}>{children}</label>
      ) : null}
    </div>
  );
};

Checkbox.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default Checkbox;
