import React, { useState, useCallback } from 'react';
import { createContext } from "use-context-selector";
export const StoreContext = createContext(null);

const defaultState = {
  isAuth: null,
}

const StoreContextProvider = ({ children }) => {
  const [store, setStore] = useState(defaultState);

  const changeStoreProp = useCallback((name, value) => {
    setStore(prevState => ({ ...prevState, [name]: value }));
  }, [setStore])

  return (
    <StoreContext.Provider value={{ changeStoreProp, ...store }}>
      {children}
    </StoreContext.Provider>
  )
}

export default StoreContextProvider;
