import React, { useState } from 'react';
import Heading from 'uikit/components/Heading';
import styles from './Authorization.pcss';
import axios from "axios";
import qs from "qs";
import Button from 'uikit/components/Button';
import { useContextSelector } from "use-context-selector";
import { StoreContext } from "../../contexts";
import {BASE_URL} from "../../api";
import {InputBase} from "../../StyledComponents/StyledComponents";

const Authorization = () => {
  const changeStoreProp = useContextSelector(StoreContext, state => state.changeStoreProp);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isBtnDisabled, setIsBtnDisabled] = useState();

  const authorizationRequest = async () => {
    try {
      const reqData = { username, password, client_id: 'client', client_secret: 'someSecret', grant_type: 'password', scope: 'cutter.api' }
      const res = await axios.post(BASE_URL + '/connect/token', qs.stringify(reqData));
      localStorage.setItem('cutter_token', res.data.access_token);
      changeStoreProp('isAuth', true);
    } catch (e) {
      setIsBtnDisabled(false);
      alert('password or login error');
      console.log(e);
    }
  }
  const onBtnClick = () => authorizationRequest();

  const changeUserName = (e) => {
    setUsername(e.currentTarget.value);
  }

  const changePassword = (e) => {
    setPassword(e.currentTarget.value);
  }

  return (
    <div className={styles.form}>
      <Heading className={styles.title} level={2} isStyled={false}>Авторизация в beel.ink</Heading>
      <InputBase className={styles.loginInput} focus value={username} onChange={changeUserName} />
      <InputBase className={styles.passInput} name="password" type="password" value={password} onChange={changePassword} />
      <Button disabled={isBtnDisabled} onClick={onBtnClick}>Войти</Button>
    </div>
  );
}

export default Authorization;
