import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Preloader from 'uikit/components/Preloader';
import Button from 'uikit/components/Button';
import styles from './Table.pcss';

function TableBody({ head, values, operations, onRowClick }) {
  const handleRowClick = useCallback(row => () => {
    if (onRowClick) {
      onRowClick(row);
    }
  }, [onRowClick]);

  return (
    <tbody className={styles.body}>
      {values.map((row, i) => (
        <tr
          key={i}
          className={cx(
            styles.bodyRow,
            {
              [styles.rowSelectable]: onRowClick,
              [styles.rowHighlighted]: row.highlighted,
            },
          )}
          onClick={handleRowClick(row)}
        >
          {operations && (
            <td className={styles.bodyCell} key="operations">
              {operations(row)}
            </td>
          )}
          {head.map(({ id, convert }, y) => (
            <td className={styles.bodyCell} key={y}>
              {convert ? convert(row[id]) : row[id]}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}

export default function Table({
  head,
  values = [],
  loadingState = 'initial',
  onRefresh = noop,
  operations = null,
  sortBy = null,
  sortDirection = 'asc',
  setSort = noop,
  onRowClick,
}) {
  const onHeadCellClick = useCallback(id => () => {
    setSort({ prop: id });
  }, [setSort]);

  return (
    <div className={styles.tableWrap}>
      <table className={styles.table}>
        <thead className={styles.head}>
          <tr>
            {operations && (
              <td key="operations" />
            )}
            {head.map(({ label, id, ...h }) => {
              const shouldSort = h.sortBy
              const iconClass = cx(
                styles.sortIcon,
                {
                  [styles.active]:  sortBy,
                  [styles.rotated]: sortDirection === 'desc',
                },
              );
              return (
                <td className={styles.headCell} key={id} onClick={shouldSort ? onHeadCellClick(id) : () => {}}>
                  <div className={styles.tableSortLabel}>
                    <div className={styles.tableSort}>
                      {shouldSort && <ArrowDropDown className={iconClass} />}
                      {label}
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
        </thead>

        {(loadingState === 'succeed' && values.length > 0) && (
          <TableBody
            head={head}
            values={values}
            loadingState={loadingState}
            onRefresh={onRefresh}
            operations={operations}
            onRowClick={onRowClick}
          />
        )}
      </table>

      {(loadingState === 'failed') && (
        <div className={cx(styles.statusBox, styles.failedBox)}>
          <p>Не удалось загрузить данные</p>
          <Button onClick={onRefresh}>Повторить</Button>
        </div>
      )}

      {(loadingState === 'succeed' && values.length <= 0) && (
        <div className={styles.statusBox}>
          <p className={styles.noMatches}>Нет совпадений</p>
        </div>
      )}

      {(loadingState === 'requested') && (
        <div className={cx(styles.statusBox, styles.preloaderBox)}>
          <Preloader />
        </div>
      )}
    </div>
  );
}

Table.propTypes = {
  head: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.node.isRequired,
      label: PropTypes.node.isRequired,
      convert: PropTypes.func,
    }),
  ).isRequired,
  values: PropTypes.arrayOf(PropTypes.object),
  loadingState: PropTypes.oneOf(['initial', 'requested', 'succeed', 'failed']),
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  setSort: PropTypes.func,
  onRefresh: PropTypes.func,
  operations: PropTypes.func,
  onRowClick: PropTypes.func,
};
